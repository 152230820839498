import styled from "styled-components";

export const ButtonBackground = styled.button`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  margin-left: 4px;
  background-color: ${(props) =>
		props.theme.colors.neutrals.secondary.gray.divider2};
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

export const Actions = styled.div<{ rightPad?: boolean }>`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  padding-right: ${(props) => (props.rightPad ? "8px" : "0")};
`;

export const Dot = styled.a`
  width: 100%;
  cursor: pointer;

  span {
    display: block;
    width: 100%;
    height: 4px;
    margin: 8px 0;
    background: #5c5c5c;
  }

  &.active span {
    background: #b3b3b3;
  }
`;

export const Dots = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 8px 0;
`;

export const Item = styled.div`
  opacity: 0;
  display: none;

  width: 100%;
  height: auto;

  &.active {
    animation: fade 0.7s ease-in-out forwards;
    display: block;
  }


  @keyframes fade {
    from {
      opacity: 0.7;
    }
    to {
      opacity: 1;
    }
  }
`;

export const PrevButton = styled.a`
  z-index: 100;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 33px;
  height: 90px;

  visibility: hidden;
  opacity: 0;
  display: none;
  animation-name: fade;
  animation-duration: 0.5s;

  @keyframes fade {
    from {
      opacity: 0.3;
      visibility: 0.3;
    }
    to {
      opacity: 1;
      visibility: 1;
    }
  }
`;

export const NextButton = styled.a`
  z-index: 100;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% - 33px);
  width: 33px;
  height: 90px;

  visibility: hidden;
  opacity: 0;
  display: none;
  animation-name: fade;
  animation-duration: 0.5s;

  @keyframes fade {
    from {
      opacity: 0.3;
      visibility: 0.3;
    }
    to {
      opacity: 1;
      visibility: 1;
    }
  }
`;

export const Items = styled.div`
  position: relative;

  &.no-margin {
    margin: 0 -24px;

    @media ${(props) => props.theme.device.sm} {
      margin: 0;
    }
  }

  @media ${(props) => props.theme.device.lg} {
    &:hover {
      ${PrevButton}, ${NextButton} {
        opacity: 1;
        visibility: visible;
        display: block;
      }
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CarouselTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;
