import { ArtistCard } from "@components/cards/ArtistCard";
import { ChartCard } from "@components/cards/ChartCard";
import { LabelCard } from "@components/cards/LabelCard";
import { ReleaseCard } from "@components/cards/ReleaseCard";
import { Carousel } from "@components/core/Carousel";
import { HeadingH2 } from "@components/typography/Typography.style";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { Chart } from "@models/Chart";
import { Artist } from "@models/artist";
import { Label } from "@models/label";
import { Release } from "@models/release";
import { device } from "@styles/theme";
import { useRouter } from "next/router";
import { useState } from "react";
import { GridRow, GridWrapper, Wrapper } from "./GridSlider.style";

type Grid = Artist | Chart | Label | Release;

interface Props {
	type: string;
	title?: string;
	data: Grid[];
	rows: number;
	wideGrid?: boolean;
	showViewAll?: boolean;
	showTitle?: boolean;
	showArtistName?: boolean;
	hideDots?: boolean;
	isDjChart?: boolean;
	dataTestId?: string;
	location?: string;
	handleReleaseClick?: (release: Release, index: number) => void;
}

const GridSlider: React.FC<Props> = ({
	type,
	title,
	data,
	rows,
	wideGrid,
	showViewAll = false,
	showTitle = true,
	showArtistName = true,
	hideDots = false,
	isDjChart = false,
	dataTestId,
	location,
	handleReleaseClick,
}) => {
	const { query } = useRouter();
	const [currentPage, setCurrentPage] = useState(0);
	// allow only one release to have controls open
	const [itemControlsOpen, setItemControlsOpen] = useState<number>();

	// we use device width to determine chunk size
	const isSm = useMediaQuery({ query: device.sm });
	const isLg = useMediaQuery({ query: device.lg });
	const isXl = useMediaQuery({ query: device.xl });
	const isXxl = useMediaQuery({ query: device.xxl });

	let chunkSize = rows * 2; // starting from smalest screen so 2 cards per row to display
	if (isXxl) {
		chunkSize = rows * (wideGrid ? 7 : 5);
	} else if (isXl) {
		chunkSize = rows * (wideGrid ? 7 : 5);
	} else if (isLg) {
		chunkSize = rows * (wideGrid ? 6 : 4); // on lg (1024px width) show 4 cards per row
	} else if (isSm) {
		chunkSize = rows * (wideGrid ? 5 : 3); // starting from sm screen we show 3 cards per row
	}

	const rowSize = chunkSize / rows;

	const chunked: Array<Grid[]> = [];
	for (let i = 0; i < data.length; i += chunkSize) {
		const chunk = data.slice(i, i + chunkSize);
		chunked.push(chunk);
	}

	const getRowItems = (items: Grid[]) => {
		const rowItems: Array<Grid[]> = [];
		for (let i = 0; i < items.length; i += rowSize) {
			const c = items.slice(i, i + rowSize);
			rowItems.push(c);
		}
		return rowItems;
	};

	const viewAllPath =
		showViewAll && query.q ?
			`/search/${type}?q=${encodeURIComponent(query.q as string)}` :
			undefined;

	const renderGridCard = (item: Grid, index: number): any => {
		switch (type) {
			case "artists":
				return (
					<ArtistCard
						key={`artist-grid-card${item.id}`}
						artist={item as Artist}
					/>
				);
			case "charts":
				return (
					<ChartCard
						location={location}
						dataTestId={dataTestId}
						key={`chart-grid-card${item.id}`}
						chart={item as Chart}
						showTitle={showTitle}
						showArtistName={showArtistName}
						isDjChart={isDjChart}
						itemControlsOpen={itemControlsOpen}
						setItemControlsOpen={setItemControlsOpen}
					/>
				);
			case "labels":
				return (
					<LabelCard
						key={`label-grid-card-${item.id}`}
						label={item as Label}
						location={title}
						className={wideGrid ? "wide" : undefined}
					/>
				);
			case "releases":
				return (
					<ReleaseCard
						index={index}
						key={`release-grid-card-${item.id}`}
						location={title}
						release={item as Release}
						className={wideGrid ? "wide" : undefined}
						dataTestId={dataTestId}
						handleReleaseClick={handleReleaseClick}
						itemControlsOpen={itemControlsOpen}
						setItemControlsOpen={setItemControlsOpen}
					/>
				);
		}
	};

	return (
		<Wrapper>
			<Carousel
				title={<HeadingH2>{title}</HeadingH2>}
				showDots={chunked.length > 1 && !hideDots}
				showRightNav={chunked.length > 1 && !showViewAll}
				viewAllPath={viewAllPath}
				onPageChange={setCurrentPage}
			>
				{chunked.map((items, index) => {
					return (
						<GridWrapper key={`${type}-grid-wrapper-${index}-${currentPage}`}>
							{getRowItems(items).map((row, index) => (
								<GridRow key={`${type}-grid-chunk-${index}`}>
									{row.map((item) => renderGridCard(item, index))}
								</GridRow>
							))}
						</GridWrapper>
					);
				})}
			</Carousel>
		</Wrapper>
	);
};

export default GridSlider;
